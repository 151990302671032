import React from "react";
import moment from "moment";

function AccomodationConfirmationBookedHotelSection({ booking }) {
  if (!booking) return <></>;

  const calculateNights = () => {
    if (!booking?.requestBody?.start_date || !booking?.requestBody?.end_date) {
      return "0 nights";
    }

    const startDate = moment(booking?.requestBody.start_date);
    const endDate = moment(booking?.requestBody.end_date);

    if (
      !startDate.isValid() ||
      !endDate.isValid() ||
      endDate.isBefore(startDate)
    ) {
      return "0 nights";
    }

    const nights = endDate.diff(startDate, "days");
    return nights === 1 ? "1 night" : `${nights} nights`;
  };

  return (
    <div className="w-full flex flex-col mt-4">
      <div className="flex items-center gap-1 mb-1">
        <img src="/IconHotel2.svg" alt="" className="h-5 object-contain" />
        <span className="text-xl font-bold">
          {booking?.holdBookingRes?.name || booking?.requestBody?.name}
        </span>
      </div>
      <div className="w-full bg-[#F3F8FF] border-t-2 border-primary1 p-4 flex flex-wrap gap-2 justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order ID</span>
          <span className="text-sm text-primary1 font-bold">
            {booking?.bookingId}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order Date</span>
          <span className="text-sm font-bold">
            {moment(booking?.createdAt).format("DD/MM/YYYY HH:mm A")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Period</span>
          <span className="text-sm font-bold">
            {moment(
              booking?.holdBookingRes?.start_date ||
                booking?.requestBody?.start_date
            ).format("DD MMM")}{" "}
            -{" "}
            {moment(
              booking?.holdBookingRes?.end_date ||
                booking?.requestBody?.end_date
            ).format("DD MMM")}{" "}
            ({calculateNights()})
          </span>
        </div>
      </div>
    </div>
  );
}

export default AccomodationConfirmationBookedHotelSection;
